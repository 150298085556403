* {
  padding: 0;
  margin: 0;
  box-sizing: none;
  scroll-behavior: smooth;
  body {
    overflow-x: hidden;
  }
  h1 {
    font-family: "Bebas Neue", Impact, "Haettenschweiler", "Arial Narrow Bold",
      sans-serif;
  }
  p,
  a,
  h2,
  button,
  h3 {
    font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif !important;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: inherit;
  }
}
.dialog-update {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 2rem;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 90%;
  width: 500px;
  text-align: center;


  h1 {
    margin-bottom: 1rem;
    color: #d62828; // Matching your site's green color from holiday-container
  }

  p {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  }

  button {
    background-color: #d62828;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

//Holiday Reminder Container
.holiday-container {
  // position:fixed;
  // top:0;
  // left:0;
  // right:0;
  position: sticky;
  top: 0;
  z-index: 69;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #6a994e;
  padding: 1rem;
  color: white;
  p {
    line-height: 1.5rem;
  }
  h2 {
    padding: 0.5rem;
  }
  .close-reminder {
    position: absolute;
    top: 40%;
    right: 3rem;
    cursor: pointer;
    font-size: 1.5rem;
  }

  // Iphone 6/7/8/X Plus
  @media only screen and (min-width: 375px) {
    .close-reminder {
      position: absolute;
      top: 10%;
      right: 2rem;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
}

//Mobile 300px Sizes
$subTitle300Px: 1.3rem;
$icon300Px: 1.2rem;
$address300Px: 0.7rem;
$menuLink300Px: 1rem;
$imgWidth300Px: 18em;
$carouselDot300Px: 0.7em;
$carouselArrow300Px: 1.4em;
$sectionTitle300Px: 1.2rem;
//Mobile 375px Sizes
$imgWidth375Px: 21em;
//Ipad 700px Sizes
$title700Px: 1.5rem;
$link700Px: 1.3rem;
$text700Px: 1.2rem;
$icon700Px: 1.5rem;
$address700Px: 0.9rem;
$menuLink700Px: 1rem;
$logo700Px: 13em;
$imgWidth700Px: 32em;
//Ipad Pro 834px Sizes
$imgWidth834Px: 36em;
$icon834Px: 2em;
//Mixins
@mixin flex-c-se-c {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}
@mixin flex-r-se-c {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
@mixin flex-r-sb-c {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
@mixin flex-r-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-c-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin flex-c-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-c-c-s {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-c-s-s {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
@mixin screen-dimension {
  min-height: 100vw;
  height: 100vh;
}
//Animations
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

//Mobile Sizes
$smLogo: 7.5rem;
$smP: 1rem;
$smLi: 1rem;
$smH2: 1.3rem;
$smH1: 1.5rem;
//Ipad Sizes
$mdLogo: 14.5rem;
$mdP: 1.8rem;
$mdLi: 1rem;
$mdH2: 1.8rem;
$mdH1: 3.5rem;
//Laptop
$lgLogo: 13.5rem;
$lgP: 1rem;
$lgLi: 1rem;
$lgH2: 1.8rem;
$lgH1: 3.5rem;
//Imports
@import "./Navigation";
@import "./Home";
@import "./Buffet";
@import "./Takeout";
@import "./Menu";
@import "./Footer";
@import "./Unknown";
